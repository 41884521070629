import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

export type SkeletonByTimeoutProps = {
    timeOut?: number;
    children?: JSX.Element | JSX.Element[];
    onOverTimeLoading?: () => void;
    renderAfterLoading?: JSX.Element | JSX.Element[];
};

const SkeletonByTimeout = ({
    timeOut = 5000,
    children,
    onOverTimeLoading,
    renderAfterLoading
}: SkeletonByTimeoutProps) => {
    const [isOverLoadingTime, setOverLoadingTime] = useState<boolean>(false);
    const variantTimeOut = useRef<any>(null);
    const startTime = useCallback(() => {
        variantTimeOut.current = setTimeout(() => {
            clearTimeout(variantTimeOut.current);

            setOverLoadingTime(true);
            onOverTimeLoading && onOverTimeLoading();
        }, timeOut);
    }, [onOverTimeLoading, timeOut]);

    useEffect(() => {
        startTime();

        return () => {
            clearTimeout(variantTimeOut.current);
        };
    }, [startTime]);

    if (isOverLoadingTime) {
        if (!!renderAfterLoading) {
            return <div>{renderAfterLoading}</div>;
        }

        return null;
    }
    if (!children) return null;

    return children;
};

export default SkeletonByTimeout;
