/* eslint-disable no-redeclare */
import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";
import styles from "./Text.module.scss";

export type TextType =
  | "headline-1"
  | "headline-2"
  | "headline-3"
  | "headline-4"
  | "title-1"
  | "title-2"
  | "title-3"
  | "title-4"
  | "title-5"
  | "body-1"
  | "body-2"
  | "body-3"
  | "body-4"
  | "label-1"
  | "label-2"
  | "label-3"
  | "label-4"
  | "label-5";

export interface TextProps {
  id?: string;
  type?: TextType;
  weight?: 400 | 500 | 600 | 700 | 800 | 900;
  children?: ReactNode;
  color?: string;
  className?: string;
  onClick?: () => void;
  htmlTag?: string;
  dangerouslySetInnerHTML?: {
    __html: string | TrustedHTML;
  };
  style?: CSSProperties;
  htmlFor?: string;
}

const Text: FC<TextProps> = ({
  type = "body-3",
  children,
  color,
  className,
  onClick,
  htmlTag,
  weight,
  style,
  id,
  ...rest
}) => {
  const typeList: string[] = type?.split("-") || [];

  const renderTag = (): string => {
    if (htmlTag) return htmlTag;

    switch (typeList[0]) {
      case "headline":
        return `h${typeList[1]}`;
      case "display":
      case "title":
        return "div";
      case "body":
        return "div";
      default:
        return "div";
    }
  };

  const CustomTag = renderTag() as keyof JSX.IntrinsicElements;

  return (
    <CustomTag
      id={id}
      className={classNames(
        styles.text,
        styles[`${type.replaceAll("-", "_")}`],
        styles[`${typeList[0]}`],
        className,
        {
          "cursor-pointer": !!onClick,
        }
      )}
      style={{ color, fontWeight: !weight ? undefined : weight, ...style }}
      onClick={onClick}
      {...rest}
    >
      {children && children}
    </CustomTag>
  );
};

export default Text;
