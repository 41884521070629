import classNames from "classnames";
import { matchEmphasize } from "~/utils/transform";
import styles from "./ProductItem.module.scss";
import Skeleton from "react-loading-skeleton";

export type Props = {
  name?: string;
  search?: string;
  loading?: boolean;
  lineClamp?: number;
  className?: string;
  href?: string;
};
const ProductName = ({
  name,
  search,
  loading,
  lineClamp = 2,
  className,
  href,
}: Props) => {
  const newName =
    name?.replace(
      new RegExp(`^${process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND}`),
      ""
    ) || "";

  if (!name && !!loading) {
    return (
      <>
        <Skeleton />
        <Skeleton width="70%" />
      </>
    )
  }

  if (!name) return <></>;

  return (
    <a href={href || undefined}>
      <h3
        className={classNames(
          `line-clamp-${lineClamp} hover:underline`,
          styles.title,
          className
        )}
        dangerouslySetInnerHTML={{
          __html: matchEmphasize({ name: newName, search }) || "",
        }}
      />
    </a>
  );
};
export default ProductName;
