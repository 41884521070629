import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from "react";
import Icon from "../Icon";
import { IconNames } from "../Icon/Icon.type";
import styles from "./Button.module.scss";

export interface ButtonProps
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "type"
  > {
  type?: "solid" | "outline" | "borderless" | "icon";
  variant?: "primary" | "secondary" | "tertiary" | "quaternary" | "danger";
  htmlType?: "button" | "submit" | "reset";
  size?: "large" | "medium" | "small";
  loading?: boolean;
  tooltip?: string;
  shape?: "normal" | "square";
  prefixIcon?: IconNames | JSX.Element;
  suffixIcon?: IconNames;
}

const Button: FC<ButtonProps> = ({
  type = "solid",
  variant = "primary",
  size = "medium",
  htmlType,
  tooltip,
  shape,
  disabled,
  prefixIcon,
  suffixIcon,
  children,
  className,
  loading,
  ...rest
}) => {
  return (
    <button
      disabled={disabled || loading}
      className={classNames(
        "transition-all duration-300 ease-in-out",
        styles.dk_btn,
        styles[`btn_${type}`],
        styles[`btn_${size}`],
        styles[`btn_${variant}`],
        styles[`btn_${shape}`],
        {
          [styles.disabled]: disabled || loading,
          [styles.loading]: loading,
        },
        className
      )}
      {...rest}
      type={htmlType}
    >
      {prefixIcon && typeof prefixIcon === "string" ? (
        <Icon name={prefixIcon} size={size === "small" ? 20 : 24} />
      ):
        prefixIcon && prefixIcon as JSX.Element
      }
     {loading && <Icon name="status"  className="animate-spin" size={size === "small" ? 20 : 24} />} {children}
      {suffixIcon && (
        <Icon name={suffixIcon} size={size === "small" ? 20 : 24} />
      )}
    </button>
  );
};

export default Button;
